import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  GetStartedLink,
  ImageWithContent,
  ShopCTAButton,
  SwooshDivider
} from '@rategravity/marketing-components';
import { OwnUpDetail } from '@rategravity/own-up-component-library';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled, { css } from 'styled-components';
import GreenDotsBlob4SVG from '../../../images/green-dots-blob-4.svg';
import NerdWalletLogo from '../../../images/nerdwallet-logo.png';
import { colors } from '../../../modules/colors';
import { www } from '../../../modules/navigation';
import { Header } from '../../typography/icon-landing';

const ContentWrapperStyle = createOwnUpStyle({
  marginLeft: '30px',
  variants: {
    smallAndDown: {
      margin: '0 20px 35px 20px'
    }
  }
});

export const ContentWrapper = createOwnUpComponent('div', ContentWrapperStyle);

export const CTAButtonStyle = css`
  display: inline-block;
  margin-top: 20px;
  max-width: 300px;
  background-color: ${colors.LOGO_SHADOW};
  border-color: ${colors.LOGO_SHADOW};
  color: ${colors.WHITE};
  :hover {
    background-color: ${colors.PRIMARY};
    border-color: ${colors.PRIMARY};
    color: ${colors.WHITE};
  }
`;

export const CTAButton = styled(ShopCTAButton)`
  ${CTAButtonStyle}
`;

const NerdWalletPermissions = () => (
  <div style={{ marginTop: '24px' }}>
    <OwnUpDetail style={{ color: colors.LOGO_SHADOW, fontStyle: 'italic' }}>
      NerdWallet and the NerdWallet logo are trademarks owned by NerdWallet,Inc. and used with
      permission.
    </OwnUpDetail>
  </div>
);

const ThanksContainer = styled.div`
  display: grid;
  grid-template-columns: 197px 123px;
  margin-bottom: 19px;
  @media (max-width: 375px) {
    margin-top: 30px;
  }
`;

const NerdWalletThanks = () => (
  <ThanksContainer>
    <OwnUpText variant="bold" style={{ fontSize: '17px', marginTop: '2px' }}>
      Thanks for coming from
    </OwnUpText>
    <img src={NerdWalletLogo} style={{ height: '26px' }} alt="NerdWallet Logo" />
  </ThanksContainer>
);

export const HeroContent = ({
  nerdWallet = false,
  urlParams
}: {
  nerdWallet?: boolean;
  urlParams?: Record<string, string>;
}) => {
  const trackingEvent = {
    hook: useTrackingInstance(),
    name: `fybm2-hero-${nerdWallet ? 'nerdwallet' : 'default'}-getstarted`,
    properties: { ...(nerdWallet ? { urlParams } : {}) }
  };

  return (
    <ContentWrapper>
      {nerdWallet ? <NerdWalletThanks /> : null}
      <Header>
        You&apos;re looking for a great mortgage. We&apos;re here to help you find it.
      </Header>
      <OwnUpText>
        Painless comparison shopping, unbiased expert advice, and average savings of $20,000+ over
        the life of your loan.
      </OwnUpText>
      <br />
      <GetStartedLink
        www={www}
        trackingEvent={trackingEvent}
        url={`${process.env.GATSBY_ONBOARDING}`}
      >
        <CTAButton>Get Started</CTAButton>
      </GetStartedLink>
      <br />
      {nerdWallet ? <NerdWalletPermissions /> : null}
    </ContentWrapper>
  );
};

export const contentImageStyle = {
  width: '100%',
  maxWidth: '900px',
  backgroundSize: '60%',
  backgroundPosition: 'top 90% left 90%',
  '& img': {
    borderRadius: '31px'
  },
  variants: {
    mediumAndDown: {
      position: 'relative' as const
    },
    medium: {
      marginLeft: '-50px',
      paddingLeft: '10px',
      backgroundPosition: 'top 85% left 90% !important',
      left: '100px',
      '> div': {
        padding: '70px 0'
      }
    },
    smallAndDown: {
      marginBottom: '-110px',
      bottom: '100px',
      '> div': {
        margin: 'auto 20px'
      }
    }
  }
};

const HeroWrapper = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    variants: {
      medium: {
        marginLeft: '30px'
      }
    }
  })
);

export const Hero = ({
  nerdWallet = false,
  urlParams,
  Image
}: {
  Image: JSX.Element;
  nerdWallet?: boolean;
  urlParams?: Record<string, string>;
}) => {
  return (
    <>
      <HeroWrapper>
        <ImageWithContent
          Image={Image}
          imagePosition="right"
          backgroundImage={GreenDotsBlob4SVG}
          flipImageOnMobile={true}
          imageStyle={contentImageStyle}
          rowOnMedium={true}
        >
          <HeroContent nerdWallet={nerdWallet} urlParams={urlParams} />
        </ImageWithContent>
      </HeroWrapper>
      <SwooshDivider
        topColor={colors.AQUA}
        bottomColor={colors.WHITE}
        desktopMaxWidth={1280}
        mobileLateralGutter={0}
      />
    </>
  );
};
